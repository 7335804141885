import { I18n, BusinessTypesIds } from 'src/types';
import { auDefault } from './index';
import privateCompanyDefaults from '../../privateCompanyDefaults';

const i18n = (country: string): I18n => {
  return {
    ...auDefault(BusinessTypesIds.PrivateCompany, country),
    uploadPage: {
      ...privateCompanyDefaults.uploadPage,
      // Having no elements should hide business documents
      businessDocuments: {
        heading: 'Business documents',
        displayAsList: false,
        items: [],
      },
    },
  };
};

export default i18n;
