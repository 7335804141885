import US from '../us';
import { I18n, BusinessTypesIds } from 'src/types';
import soleTrader from './soleTrader';
import privateCompany from './privateCompany';
import partnership from './partnership';
import nonprofit from './nonprofit';

export const nzDefault = (entityType: string, country: string): I18n => {
  const defaults = US(entityType, country);

  const i18n: I18n = {
    ...defaults,
    registrationPage: {
      ...defaults.registrationPage,
      userAgreement: [
        {
          link: 'https://www.ofx.com/en-nz/legal/privacy-policy/',
          text: 'We use your personal information in accordance with our',
          linkText: ' Privacy Policy',
        },
        {
          link: '',
          text: ', which we encourage you to read.',
          linkText: '',
        },
      ],
    },
    businessDetailsPage: {
      ...defaults.businessDetailsPage,
      businessNumber: 'NZBN',
      relatedEntity: {
        ...defaults.businessDetailsPage.relatedEntity,
        relatedEntityBusinessName: 'Name of the',
        relatedEntityBusinessNumber: 'NZBN (optional)',
      },
    },
    dateFormat: 'dd/MM/yyyy',
    locale: 'nz',
    shareholdersForm: {
      ...defaults.shareholdersForm,
      header: 'Add a director/shareholder',
      subHeader:
        'Add a director or a shareholder who owns more than 25% of shares.',
    },
  };
  return i18n;
};

const NZ = (entityType: string, country: string): I18n => {
  switch (entityType) {
    case BusinessTypesIds.SoleTrader:
      return soleTrader(country);
    case BusinessTypesIds.Partnership:
      return partnership(country);
    case BusinessTypesIds.NonProfit:
    case BusinessTypesIds.PublicCompany:
      return nonprofit(country);
    case BusinessTypesIds.PrivateCompany:
    default:
      return privateCompany(country);
  }
};

export default NZ;
