import { I18n, BusinessTypesIds } from '../../../types';
import { nzDefault } from './index';

const thankyouPage = {
  personalDocuments: [
    {
      text: 'Photo ID',
      highlight: 'if we fail to electronically verify the identity',
    },
  ],
  businessDocuments: [
    {
      text: 'Proof of address for your residential or trading address',
    },
  ],
};

const uploadPage = {
  header: 'We require the following documents to verify your business',
  subHeader:
    'We are a fully regulated business and take security seriously, so we need some documentation to verify your business.',
  businessDocuments: {
    heading: 'Business documents',
    displayAsList: false,
    items: [],
  },
  personalDocuments: {
    heading: 'Personal documents',
    displayAsList: true,
    items: [
      {
        description:
          "Photo ID in the form of either a current <strong>Driver's License (front & back) or Passport</strong>",
      },
      {
        description:
          'If the address is not available on the Photo ID, a <strong>proof of residential address dated in the last three months</strong>. This can be:',
        listItems: ['Utility bill', 'Bank statement', 'Government letter'],
      },
    ],
    noPersonalDocumentText: 'No Personal Documents Required at this time.',
  },
};

const i18n = (country: string): I18n => {
  return {
    ...nzDefault(BusinessTypesIds.PrivateCompany, country),
    thankYouPage: {
      ...nzDefault(BusinessTypesIds.PrivateCompany, country).thankYouPage,
      personalDocuments: thankyouPage.personalDocuments,
      businessDocuments: thankyouPage.businessDocuments,
    },
    uploadPage,
  };
};

export default i18n;
