import { I18n, BusinessTypesIds } from 'src/types';
import { auDefault } from './index';
import privateCompanyDefaults from '../../privateCompanyDefaults';

const i18n = (country: string): I18n => {
  return {
    ...auDefault(BusinessTypesIds.PrivateCompany, country),
    uploadPage: {
      ...privateCompanyDefaults.uploadPage,
      personalDocuments: {
        heading: 'Personal documents',
        displayAsList: true,
        items: [
          {
            description:
              'Photo ID in the form of either a current <strong>Driver’s License or Passport.</strong> Digital photos or scans are acceptable.',
          },
        ],
        noPersonalDocumentText: 'No Personal Documents Required at this time.',
      },
      businessDocuments: {
        heading: 'Business documents',
        displayAsList: false,
        items: [
          {
            description:
              'Official document confirming the partnership details of your business. This can be <strong>your Partnership Agreement or Letter</strong>.',
          },
        ],
      },
    },
  };
};

export default i18n;
