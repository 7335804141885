import { BusinessTypesIds, I18n } from 'src/types';
import soleTrader from './soleTrader';
import privateCompany from './privateCompany';
import partnership from './partnership';
import trust from './trust';
import nonProfit from './nonProfit';
import publicCompany from './publicCompany';

function getBusinessNumber(country: string): string {
  if (country && country.toUpperCase() === 'US') {
    return 'EIN number (optional)';
  }
  return 'Business number';
}

function getMessageContent(entityTypeId: string): I18n {
  switch (entityTypeId) {
    case BusinessTypesIds.SoleTrader:
      return soleTrader;
    case BusinessTypesIds.PrivateCompany:
      return privateCompany;
    case BusinessTypesIds.Partnership:
      return partnership;
    case BusinessTypesIds.Trust:
      return trust;
    case BusinessTypesIds.NonProfit:
      return nonProfit;
    case BusinessTypesIds.PublicCompany:
      return publicCompany;
    default:
      return privateCompany;
  }
}

const US = (entityTypeId: string, country: string): I18n => {
  const i18n = getMessageContent(entityTypeId);
  return {
    ...i18n,
    businessDetailsPage: {
      ...i18n.businessDetailsPage,
      businessNumber: getBusinessNumber(country),
    },
  };
};

export default US;
