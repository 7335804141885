import { BusinessTypesIds, I18n } from 'src/types';
import soleTrader from './soleTrader';
import privateCompany from './privateCompany';
import partnership from './partnership';
import trust from './trust';

const CA = (entityTypeId: string): I18n => {
  switch (entityTypeId) {
    case BusinessTypesIds.SoleTrader:
      return soleTrader;
    case BusinessTypesIds.PrivateCompany:
      return privateCompany;
    case BusinessTypesIds.Partnership:
      return partnership;
    case BusinessTypesIds.Trust:
      return trust;
    default:
      return privateCompany;
  }
};

export default CA;
