import { I18n, RelatedEntityTypesIds } from '../../../types';
import validationMessages from '../../validationMessages';
import { thankYouPageMessages } from './../../thankyouMessages';

const i18n: I18n = {
  locale: 'us',
  genericServerErrorText: 'Sorry, something went wrong. Please try again.',
  dateFormat: 'MM/dd/yyyy',
  dateOfBirth: {
    label: 'Date of birth',
  },
  docPopover: {
    docPopoverLink: 'Why is verification  required?',
    docPopoverCloseButtonText: 'Thanks, I understand',
    docPopoverHeading:
      'Important information about procedures for opening a new account',
    docPopoverBody1:
      'To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.',
    docPopoverBody2:
      'What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.',
  },
  registrationPage: {
    header: 'Start making secure transfers today',
    subHeader: 'Are you moving money for personal or business purposes?',
    referredByText: "You've been referred by",
    personalTile: {
      title: 'Personal',
      subTitle: 'For individuals who need to move money globally',
    },
    businessTile: {
      title: 'Business',
      subTitle:
        'For companies, accountants, e-commerce businesses or sole traders',
    },
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Business email',
    countryLabel: 'Country of registration',
    businessTypeLabel: 'Business type',
    currencyHeader:
      'Tell us your needs and we’ll identify the best solution for you',
    currencySubHeader: 'What currencies are you likely to transfer?',
    fromCurrencyLabel: 'From currency',
    toCurrencyLabel: 'To currency',
    transferRangeHeader:
      'What amount do you expect to transfer yearly in your local currency?',
    transferRange1: '0 - 500,000',
    transferRange2: '500,000 - 1,000,000',
    transferRange3: '1,000,000 - 5,000,000',
    transferRange4: '5,000,000+',
    newTransferRange: {
      range1: '0 - 10,000',
      range2: '11,000 - 50,000',
      range3: '51,000 - 100,000',
      range4: '101,000 - 500,000',
      range5: '501,000 - 750,000',
      range6: '751,000 - 1,000,000',
      range7: '1,000,000 - 5,000,000',
      range8: '5,000,000+',
    },
    userAgreement: [
      {
        link: 'https://www.ofx.com/en-us/legal/privacy-policy/',
        text: 'We use your personal information in accordance with our',
        linkText: ' Privacy Policy',
      },
      {
        link: '',
        text: ', which we encourage you to read.',
        linkText: '',
      },
    ],
    accountancyPractice: {
      text: "I'm an accountant and want to use OFX for my clients",
      subTitle:
        'Please complete the form below using your practice credentials',
    },
  },
  businessDetailsPage: {
    header: 'Tell us a bit about your business',
    subHeader:
      'We are a fully regulated business and take security seriously, so we need to verify your business details.',
    aboutBusinessHeading: 'About your business',
    securityAnswer: 'Security answer',
    securityQuestion: 'Security question',
    username: 'Username',
    password: 'Password',
    createBusinessHeading: 'Create your business account',
    registeredBusinessAddress: 'Registered business address',
    autoCompanySearch: 'Search business name or business number',
    alternativeAddress:
      'The trading/operating address of the business is different to registered business address',
    tradingAddress: 'Trading/operating address',
    businessWebsite: 'Business website (optional)',
    businessIndustry: 'Business industry',
    businessIndustryGroup: 'Business industry group',
    businessIndustryDivision: 'Business industry',
    businessName: 'Business name',
    businessNumber: 'EIN number (optional)',
    relatedEntity: {
      isRelatedEntityQuestion:
        'Are you opening this account on behalf of another entity (e.g. trust)?',
      isRelatedEntityOptions: [
        { value: true, caption: 'Yes' },
        { value: false, caption: 'No' },
      ],
      entityTypeQuestion:
        'What business entity type are you opening on behalf of?',
      entityTypeOptions: [
        { id: RelatedEntityTypesIds.Trust, name: 'Trust' },
        { id: RelatedEntityTypesIds.Partnership, name: 'Partnership' },
        {
          id: RelatedEntityTypesIds.NonProfit,
          name: 'Not-for-profit (e.g. Association)',
        },
      ],
      relatedEntityBusinessName: 'Name of the',
      relatedEntityBusinessNumber: 'EIN number of the',
    },
  },
  aboutPage: {
    header: 'Tell us a bit about yourself',
    subHeader:
      'We are a fully regulated business and take security seriously, so we need to verify your personal details.',
    aboutYouHeading: 'Your details',
    errorHeader:
      'Unfortunately we cannot service your business needs at the current time.',
    button: 'Go to OFX',
    userAgreement: {
      description:
        'By clicking the Submit button, I hereby agree and consent to the [AGREEMENT] and [PRIVACY]',
      agreementLabel: 'User Agreement',
      policyLabel: 'Privacy Policy',
      agreementLink: 'https://www.ofx.com/en-us/legal/user-agreement/',
      policyLink: 'https://www.ofx.com/en-us/legal/privacy-policy/',
    },
    firstName: 'First name (legal name)',
    middleName: 'Middle name (if any)',
    lastName: 'Last name',
    email: 'Business email',
    phone: 'Phone number (landline/mobile)',
    mobile: 'Mobile number',
    address: 'Residential address',
    jobTitle: 'Which of the below best describes your position',
    percentageOwned: 'Percentage of shares you own',
  },
  digitalKycForm: {
    headerLabel: 'Tell us a bit about how OFX can help you',
    headerText:
      'As part of our licensing requirements, we’re required to understand a bit about your business and how you’ll be using our products and services',
    subHeaderLabel: 'Your needs',
    subHeaderText:
      'Your answers won’t lock you in if your needs change over time.',
    sendingFundsTo: 'Which countries do you plan on sending funds to?',
    sendingCurrencies:
      'Which currencies will you be sending to your recipient?',
    reasonsForPayments: 'What are your reason(s) for making payments?',
    otherReasonsForPayments: 'Please describe your reason for making payment',
    paymentFrequency: 'At what frequency do you expect to be making payments?',
    fundingCurrencies: 'Which currencies will you be funding your payments in?',
    roleInEntity: 'What is your role in the entity that you are registering?',
    businessRevenueGenerationMode: 'How does your business generate revenue?',
    fundsGeneratedByBusiness:
      'Are the funds you will use to make payments generated through your business operations?',
    fundsGeneratedWay:
      'Please provide detail on how these funds were generated',
    sendingFundsFromEntityOwnedAccount:
      'Will you be sending the funds to OFX from bank account(s) that are owned by the entity you are registering?',
    fundingAccountAndRelationship:
      'Please provide detail on whose accounts will be funding the payments and the relationship with the entity you are registering',
    kycGeneralComments: 'General comments',
  },
  thankYouPage: {
    ...thankYouPageMessages,
    personalDocuments: [
      {
        text: 'Name, contact details and DOB for directors and shareholders who own 25% or more of the business',
      },
      {
        text: 'Photo ID of listed directors/shareholders',
        highlight: 'if we fail to electronically verify the identity',
      },
    ],
    personalDocumentsForPassfortOnboarding: [
      {
        text: 'Be prepared to provide details related to all persons who own directly or indirectly 25% or more of the company, and all persons with the right to exercise significant influence/control/decision making authority for the company',
      },
      {
        text: 'Photo ID for yourself and listed directors/shareholders',
        highlight: 'if we fail to electronically verify the identity',
      },
    ],
    businessDocuments: [
      { text: 'EIN/Tax ID verification letter' },
      {
        text: 'An official document that confirms the ownership and management structure',
      },
    ],
  },
  shareholdersPage: {
    header: 'About your business officers & shareholders',
    subHeader:
      'We are a fully regulated business and take security seriously, so we need to verify your business & your business stakeholders.',
    buttonLabel: 'Continue',
    newOnboardingSpinner: [
      {
        firstLineMessage: "We're gathering your company information.",
        secondLineMessage: 'This could take a minute...',
      },
      {
        firstLineMessage: "We're gathering your company information.",
        secondLineMessage: 'Almost there...',
      },
    ],
    spentaErrorDialog: {
      header: "Sorry, we're unable to proceed",
      subHeader:
        'A system issue has prevented us from processing your request. Please click <a href="https://www.ofx.com/en-us/contact-us/">here</a> and reach out to an OFXpert in your region.',
      button: 'GO TO DASHBOARD',
    },
  },
  uploadPage: {
    header: 'We require the following documents to verify your business',
    subHeader:
      'We are a fully regulated business and take security seriously, so we need some documentation to verify your business.',
    businessDocuments: {
      heading: 'Business documents',
      displayAsList: true,
      items: [
        {
          description:
            'Confirmation of your Employer Identification Number (EIN) or Federal Tax ID number in the form of an <strong>EIN/Tax ID verification letter</strong>.',
        },
        {
          description: 'A copy of your latest <strong>Annual Report<strong/>.',
        },
        {
          description:
            'A copy of your <strong>organizational structure diagram.</strong>',
        },
      ],
    },
    personalDocuments: {
      heading: 'Personal documents',
      displayAsList: false,
      items: [
        {
          description:
            'Photo ID for individuals listed below in the form of either a current <strong>Driver’s License or Passport</strong>. Digital photos or scans are acceptable.',
        },
      ],
      noPersonalDocumentText: 'No Personal Documents Required at this time.',
    },
  },
  addShareholdersBox: {
    topMessage:
      'Add all shareholders who own 25% or more, plus the officers of your business.',
    bottomMessage:
      'If no single shareholder owns 25% or more, add all officers with a controlling interest. You can add up to 5 shareholders/officers.',
    buttonLabel: 'Add officer or shareholder',
  },
  shareholdersForm: {
    header: 'Officer or shareholder details',
    subHeader:
      'Shareholders who own 25% or more, plus the officers of your business.',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Email',
    mobileLabel: 'Phone number',
    addressLabel: 'Residential address',
    submitButtonLabel: 'Submit',
    discardButtonLabel: 'Discard',
    describePositionLabel:
      'Which of the below best describes the position of the shareholder/officer:',
    companyMemberRoles: {
      directorAndShareHolder: 'An officer & shareholder',
      primaryUser: 'Primary user',
      director: 'Officer only',
      beneficialOwner: 'Shareholder only',
      complexShareholderMessage:
        'Percentage of shares owned is pre-calculated based on multiple ownerships. You can proceed to add this entry and speak to an OFXpert at a later time, if any change is required.',
    },
    jobTitleLabel: 'What is their position in the business',
    sharesOwnedLabel: 'Percentage of shares they own',
    manualAddressFieldSetTitle:
      'Enter the shareholder/director residential address',
  },
  shareholdersFormSpenta: {
    header: 'Director or shareholder details',
    subHeader:
      "Please provide the information below for any director or shareholder you want to add. If you can't provide this information now, we may ask you to provide it before your account is ready for use.",
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Email',
    mobileLabel: 'Phone number',
    addressLabel: 'Residential address',
    addressLabelOptional: 'Residential address (optional)',
    submitAddButtonLabel: 'Add entry',
    submitUpdateButtonLabel: 'Update entry',
    discardButtonLabel: 'Discard',
    describePositionLabel:
      'Which of the below best describes the position of the shareholder/director:',
    companyMemberRoles: {
      directorAndShareHolder: 'An officer & shareholder',
      primaryUser: 'Primary user',
      director: 'Director only',
      beneficialOwner: 'Shareholder only',
    },
    jobTitleLabel: 'What is their position in the business',
    sharesOwnedLabel: 'Percentage of shares they own',
    manualAddressFieldSetTitle:
      'Enter the Shareholder/Director residential address',
    manualAddressFieldSetTitleOptional:
      'Enter the Shareholder/Director residential address (optional)',
    ssnTooltip: 'For faster processing, enter social security number',
    ssn: 'Social security number',
    ssnOptional: 'Social security number (optional)',
    dobOptional: 'Date of birth (optional)',
    optionalFieldsHelperAdd:
      'For faster processing, include any optional information below or proceed to add entry. If you choose to skip this for now, we may ask you to provide it later.',
    optionalFieldsHelperUpdate:
      'For faster processing, include any optional information below or proceed to update entry. If you choose to skip this for now, we may ask you to provide it later.',
  },
  manualAddress: {
    fieldSetTitle: 'Enter the shareholder/officer residential address',
  },
  userDetailsCard: {
    verified: 'VERIFIED',
    verifying: 'VERIFYING',
    unableToVerify: 'DOCUMENTATION NEEDED',
    primaryUser: 'PRIMARY USER',
    address: 'Residential address',
    dob: 'DOB',
    phone: 'Phone',
    email: 'Email',
    sharesOwned: 'Shares owned',
    notAvailable: 'n/a',
    ssn: 'SSN',
  },
  userDetailForm: {
    header: 'Your details',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Business email',
    mobileLabel: 'Phone number',
    addressLabel: 'Residential address',
    ssnLabel: 'Social Security number',
    ssnPopoverOpenButtonText: 'Why do we need this?',
    ssnPopoverCloseButtonText: 'Thanks, I understand',
    ssnPopoverHeading: 'Why does OFX require your SSN?',
    ssnPopoverBody:
      'US regulations require the collection your Social Security number to ensure the security of your account and the safety of your money transferred with us. All information provided to OFX is stored securely and kept private.',
    manualAddressFieldSetTitle: 'Enter your residential address',
  },
  validationMessages,
  primaryUserRoles: {
    shareholderOfficer: 'I am a shareholder and officer',
    shareHolder: 'I am a shareholder only',
    officer: 'I am an officer only',
    employee: 'I am an employee',
  },
};

export default i18n;
