import { I18n, LegalEntities } from 'src/types';
import GB from './locales/gb';
import US from './locales/us';
import AU from './locales/au';
import CA from './locales/ca';
import HK from './locales/hk';
import NZ from './locales/nz';
import SG from './locales/sg';
import IE from './locales/ie';
import { transformI18nForCorporateOnboarding } from './corporateOnboardingHelpers';

const i18n = (
  locale: string,
  entityTypeId?: string,
  country?: string,
  isEnableCorporateOnboardingFrontEnd?: boolean,
  isPassfortMigrated?: boolean
): I18n => {
  const i18n: I18n = getStandardi18n(locale, entityTypeId, country);
  if (isEnableCorporateOnboardingFrontEnd && isPassfortMigrated) {
    return transformI18nForCorporateOnboarding(i18n);
  }
  return i18n;
};

const getStandardi18n = (
  locale: string,
  entityTypeId?: string,
  country?: string
): I18n => {
  switch (locale) {
    case LegalEntities.AU:
      return AU(entityTypeId, country);
    case LegalEntities.CA:
      return CA(entityTypeId);
    case LegalEntities.HK:
      return HK(entityTypeId, country);
    case LegalEntities.NZ:
      return NZ(entityTypeId, country);
    case LegalEntities.SG:
      return SG(entityTypeId, country);
    case LegalEntities.GB:
      return GB(entityTypeId, country);
    case LegalEntities.US:
      return US(entityTypeId, country);
    case LegalEntities.IE:
      return IE(entityTypeId, country);
    default:
      return US(entityTypeId, country);
  }
};

export default i18n;
