import { I18n } from 'src/types';
import { newCorpOnboardingText } from './newCorporateOnboardingFlow/newCorporateOnboardingDefaults';

export const transformI18nForCorporateOnboarding = (i18n: I18n): I18n => {
  i18n = {
    ...i18n,
    ...newCorpOnboardingText,
  };
  return i18n;
};
