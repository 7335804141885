import US from '../us';
import { I18n, BusinessTypesIds } from 'src/types';
import soleTrader from './soleTrader';
import privateCompany from './privateCompany';
import trust from './trust';
import partnership from './partnership';
import nonprofit from './nonprofit';

function getBusinessNumber(entityTypeId, country): string {
  if (country && country.toUpperCase() === 'SG') {
    return 'Business registration number/UEN';
  }
  return 'Business number';
}

export const sgDefault = (entityType: string, country: string): I18n => {
  const defaults = US(entityType, country);
  return {
    ...defaults,
    registrationPage: {
      ...defaults.registrationPage,
      userAgreement: [
        {
          link: 'https://www.ofx.com/en-sg/legal/privacy-policy/',
          text: 'We use your personal information in accordance with our',
          linkText: ' Privacy Policy',
        },
        {
          link: '',
          text: ', which we encourage you to read.',
          linkText: '',
        },
      ],
    },
    businessDetailsPage: {
      ...defaults.businessDetailsPage,
      businessNumber: getBusinessNumber(entityType, country),
      relatedEntity: {
        ...defaults.businessDetailsPage.relatedEntity,
        relatedEntityBusinessName: 'Name of the',
        relatedEntityBusinessNumber: 'Business registration number (optional)',
      },
    },
    aboutPage: {
      ...defaults.aboutPage,
      phone: 'Phone number',
    },
    aboutPagePrompts: {
      OptionalNationalIdentityNumberFieldPrompt: {
        headerText: 'Have you considered our optional field?',
        subHeaderText:
          'If you have an NRIC Number, we highly recommend providing this as it may speed up your Verification process.',
        proceedBtn: 'Proceed',
        goBackBtn: 'Go back',
      },
    },
    dateFormat: 'dd/MM/yyyy',
    locale: 'sg',
    shareholdersForm: {
      ...defaults.shareholdersForm,
      header: 'Add a director/shareholder',
      subHeader:
        'Add a director or a shareholder who owns more than 25% of shares.',
    },
  };
};

const SG = (entityType: string, country: string): I18n => {
  switch (entityType) {
    case BusinessTypesIds.SoleTrader:
      return soleTrader(country);
    case BusinessTypesIds.Trust:
      return trust(country);
    case BusinessTypesIds.Partnership:
      return partnership(country);
    case BusinessTypesIds.PublicCompany:
    case BusinessTypesIds.NonProfit:
      return nonprofit(country);
    case BusinessTypesIds.PrivateCompany:
    default:
      return privateCompany(country);
  }
};

export default SG;
