import { I18n, BusinessTypesIds } from '../../../types';
import { auDefault } from './index';
import soleTraderDefaults from '../../soleTraderDefaults';

const i18n = (country: string): I18n => {
  return {
    ...auDefault(BusinessTypesIds.SoleTrader, country),
    uploadPage: {
      ...soleTraderDefaults.uploadPage,
      personalDocuments: {
        heading: 'Personal documents',
        displayAsList: true,
        items: [
          {
            description:
              'Photo ID in the form of either a current <strong>Driver’s License or Passport. Digital photos or scans are acceptable</strong>.',
          },
        ],
        noPersonalDocumentText: 'No Personal Documents Required at this time.',
      },
      businessDocuments: {
        ...soleTraderDefaults.uploadPage.businessDocuments,
        items: [],
      },
    },
  };
};

export default i18n;
