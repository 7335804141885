import defaults from '../../soleTraderDefaults';
import { thankYouPageMessages } from './../../thankyouMessages';

const soleTrader = {
  ...defaults,
  locale: 'ca',
  dateFormat: 'dd/MM/yyyy',
  thankYouPage: {
    ...thankYouPageMessages,
    personalDocuments: [
      {
        text: 'Photo ID',
        highlight: 'if we fail to electronically verify the identity',
      },
    ],
    businessDocuments: [
      {
        text: 'Official document confirming the registration details of your business',
      },
    ],
  },
  uploadPage: {
    ...defaults.uploadPage,
    businessDocuments: {
      ...defaults.uploadPage.businessDocuments,
      items: [
        {
          description:
            'Official document confirming the registration details of your business. This can be:',
          listItems: [
            'Master Business License;',
            'Vendors Permit;',
            'HST# Letter.',
          ],
        },
      ],
    },
  },
};

export default soleTrader;
