import { I18n, BusinessTypesIds } from 'src/types';
import { hkDefault } from './index';
import privateCompanyDefaults from '../../privateCompanyDefaults';

const thankyouPage = {
  personalDocuments: [
    {
      text: 'Name, contact details and DOB for directors and shareholders who own more than 25% of the business',
    },
    {
      text: 'Photo ID for yourself and listed directors/shareholders',
    },
  ],
  personalDocumentsForPassfortOnboarding: [
    {
      text: 'Be prepared to provide details related to all persons who own directly or indirectly 25% or more of the company, and all persons with the right to exercise significant influence/control/decision making authority for the company',
    },
    {
      text: 'Photo ID for yourself and listed directors/shareholders',
      highlight: 'if we fail to electronically verify the identity',
    },
  ],
  businessDocuments: [
    {
      text: 'Proof of address for the trading address',
      highlight:
        'if your registered and trading address differ to company registry details',
    },
    {
      text: 'Scanned copy of trust deed for shareholding trust/s',
      highlight:
        'if more than 25% of your company shares are beneficially owned by a trust',
    },
  ],
};

const i18n = (country: string): I18n => {
  return {
    ...hkDefault(BusinessTypesIds.PrivateCompany, country),
    thankYouPage: {
      ...hkDefault(BusinessTypesIds.PrivateCompany, country).thankYouPage,
      personalDocuments: thankyouPage.personalDocuments,
      businessDocuments: thankyouPage.businessDocuments,
    },
    shareholdersPage: {
      header: 'About your business directors & shareholders',
      subHeader:
        'We are a fully regulated business and take security seriously, so we need to verify your business & your business stakeholders.',
      buttonLabel: 'Continue',
      newOnboardingSpinner: [
        {
          firstLineMessage: "We're gathering your company information.",
          secondLineMessage: 'This could take a minute...',
        },
        {
          firstLineMessage: "We're gathering your company information.",
          secondLineMessage: 'Almost there...',
        },
      ],
      spentaErrorDialog: {
        header: "Sorry, we're unable to proceed",
        subHeader:
          'A system issue has prevented us from processing your request. Please click <a href="https://www.ofx.com/en-us/contact-us/">here</a> and reach out to an OFXpert in your region.',
        button: 'Continue',
      },
    },
    addShareholdersBox: {
      topMessage:
        'Add all shareholders who own more than 25%, plus the directors of your business.',
      bottomMessage:
        'If no single shareholder owns more than 25%, add all directors with a controlling interest. You can add up to 5 shareholders/directors.',
      buttonLabel: 'Add director or shareholder',
    },
    shareholdersForm: {
      ...privateCompanyDefaults.shareholdersForm,
      header: 'Director or shareholder details',
      subHeader:
        'Shareholders who own more than 25%, plus the directors of your business.',
      describePositionLabel:
        'Which of the below best describes the position of the shareholder/director:',
      companyMemberRoles: {
        directorAndShareHolder: 'A director & shareholder',
        primaryUser: 'Primary user',
        director: 'Director only',
        beneficialOwner: 'Shareholder only',
        complexShareholderMessage:
          'Percentage of shares owned is pre-calculated based on multiple ownerships. You can proceed to add this entry and speak to an OFXpert at a later time, if any change is required.',
      },
      jobTitleLabel: 'What is their position in the business',
      sharesOwnedLabel: 'Percentage of shares they own',
      manualAddressFieldSetTitle:
        'Enter the Shareholder/Director residential address',
    },
    shareholdersFormSpenta: {
      ...privateCompanyDefaults.shareholdersFormSpenta,
      header: 'Director or shareholder details',
      subHeader:
        "Please provide the information below for any director or shareholder you want to add. If you can't provide this information now, we may ask you to provide it before your account is ready for use.",
      describePositionLabel:
        'Which of the below best describes the position of the shareholder/director:',
      companyMemberRoles: {
        directorAndShareHolder: 'A director & shareholder',
        primaryUser: 'Primary user',
        director: 'Director only',
        beneficialOwner: 'Shareholder only',
      },
      jobTitleLabel: 'What is their position in the business',
      sharesOwnedLabel: 'Percentage of shares they own',
      manualAddressFieldSetTitle:
        'Enter the Shareholder/Director residential address',
    },
    uploadPage: {
      ...privateCompanyDefaults.uploadPage,
      businessDocuments: {
        heading: 'Business documents',
        displayAsList: false,
        items: [
          {
            description:
              'Official document confirming the partnership details of your business. This can be <strong>your Partnership Agreement or Letter</strong>.',
          },
        ],
      },
      personalDocuments: {
        heading: 'Personal documents',
        displayAsList: false,
        items: [
          {
            description:
              'Photo ID for individuals listed below in the form of either a <strong>Hong Kong Permanent ID card or Passport</strong>',
          },
          {
            description: 'Digital photos or scans are acceptable.',
          },
        ],
        noPersonalDocumentText: 'No Personal Documents Required at this time.',
      },
    },
  };
};

export default i18n;
