import { BusinessTypesIds, I18n } from 'src/types';
import soleTrader from './soleTrader';
import privateCompany from './privateCompany';
import partnership from './partnership';

function getBusinessNumber(entityTypeId, country): string {
  if (country && country.toUpperCase() === 'IE') {
    return 'Company registration number';
  }
  return 'Business number';
}

function getMessageContent(entityTypeId): I18n {
  switch (entityTypeId) {
    case BusinessTypesIds.SoleTrader:
      return soleTrader;
    case BusinessTypesIds.PrivateCompany:
      return privateCompany;
    case BusinessTypesIds.Partnership:
    case BusinessTypesIds.Trust:
    case BusinessTypesIds.NonProfit:
    case BusinessTypesIds.PublicCompany:
      return partnership;
    default:
      return privateCompany;
  }
}

const IE = (entityTypeId: string, country: string): I18n => {
  const i18n = getMessageContent(entityTypeId);

  return {
    ...i18n,
    businessDetailsPage: {
      ...i18n.businessDetailsPage,
      businessNumber: getBusinessNumber(entityTypeId, country),
    },
  };
};

export default IE;
