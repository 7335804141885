import defaults from '../../privateCompanyDefaults';
import { thankYouPageMessages } from './../../thankyouMessages';

const privateCompany = {
  ...defaults,
  locale: 'ca',
  dateFormat: 'dd/MM/yyyy',
  registrationPage: {
    ...defaults.registrationPage,
    userAgreement: [
      {
        link: 'https://www.ofx.com/en-ca/legal/privacy-policy/',
        text: 'We use your personal information in accordance with our',
        linkText: ' Privacy Policy',
      },
      {
        link: '',
        text: ', which we encourage you to read.',
        linkText: '',
      },
    ],
  },
  businessDetailsPage: {
    ...defaults.businessDetailsPage,
    businessNumber: 'Business number (optional)',
  },
  thankYouPage: {
    ...thankYouPageMessages,
    personalDocuments: [
      {
        text: 'Name, contact details and DOB for directors and shareholders who own 25% or more of the business',
      },
      {
        text: 'Photo ID of listed directors/shareholders',
        highlight: 'if we fail to electronically verify the identity',
      },
    ],
    personalDocumentsForPassfortOnboarding: [
      {
        text: 'Be prepared to provide details related to all persons who own directly or indirectly 25% or more of the company, and all persons with the right to exercise significant influence/control/decision making authority for the company',
      },
      {
        text: 'Photo ID for yourself and listed directors/shareholders',
        highlight: 'if we fail to electronically verify the identity',
      },
    ],
    businessDocuments: [
      {
        text: 'An official document that confirms the ownership and management structure',
      },
    ],
  },
  shareholdersPage: {
    header: 'Your business’s ownership and management',
    subHeader:
      'We are a fully regulated business and take security seriously, so we need to understand your business’s ownership and management.',
    buttonLabel: 'Continue',
    newOnboardingSpinner: [
      {
        firstLineMessage: "We're gathering your company information.",
        secondLineMessage: 'This could take a minute...',
      },
      {
        firstLineMessage: "We're gathering your company information.",
        secondLineMessage: 'Almost there...',
      },
    ],
    spentaErrorDialog: {
      header: "Sorry, we're unable to proceed",
      subHeader:
        'A system issue has prevented us from processing your request. Please click <a href="https://www.ofx.com/en-us/contact-us/">here</a> and reach out to an OFXpert in your region.',
      button: 'GO TO DASHBOARD',
    },
  },
  addShareholdersBox: {
    topMessage:
      'Please add all individuals who own 25% or more of your business, plus any key members of management with significant responsibilities (Officers / Directors etc.)',
    bottomMessage:
      'If no individual owns 25% or more, please add all parties with a controlling interest. You can add up to 5.',
    buttonLabel: 'Add Ownership or Management',
  },
  shareholdersForm: {
    ...defaults.shareholdersForm,
    header: 'Add Ownership or Management',
    subHeader:
      'Please add any individual who owns 25% or more of your business or any member of management with significant responsibilities (Officers / Directors etc.)',
    describePositionLabel:
      'Which of the following best describes this individual’s relationship to the business?',
    jobTitleLabel: 'What is their role/title in the business?',
    sharesOwnedLabel: 'Percentage of ownership',
    companyMemberRoles: {
      directorAndShareHolder: 'Management & ownership',
      primaryUser: 'Primary user',
      director: 'Management',
      beneficialOwner: 'Ownership',
      complexShareholderMessage:
        'Percentage of shares owned is pre-calculated based on multiple ownerships. You can proceed to add this entry and speak to an OFXpert at a later time, if any change is required.',
    },
  },
  shareholdersFormSpenta: {
    ...defaults.shareholdersFormSpenta,
    header: 'Add Ownership or Management',
    subHeader:
      "Please provide the information below for any director or shareholder you want to add. If you can't provide this information now, we may ask you to provide it before your account is ready for use.",
    describePositionLabel:
      'Which of the following best describes this individual’s relationship to the business?',
    jobTitleLabel: 'What is their role/title in the business?',
    sharesOwnedLabel: 'Percentage of ownership',
    companyMemberRoles: {
      directorAndShareHolder: 'Management & ownership',
      primaryUser: 'Primary user',
      director: 'Management',
      beneficialOwner: 'Ownership',
      complexShareholderMessage:
        'Percentage of shares owned is pre-calculated based on multiple ownerships. You can proceed to add this entry and speak to an OFXpert at a later time, if any change is required.',
    },
  },
  uploadPage: {
    ...defaults.uploadPage,
    businessDocuments: {
      ...defaults.uploadPage.businessDocuments,
      items: [
        {
          description:
            'An official document that <strong>confirms the ownership and management structure</strong> of your business. This can be in the form of:',
          listItems: [
            'Articles of incorporation;',
            'Operating Agreement;',
            'Company Report;',
            'Latest Tax Filing.',
          ],
        },
      ],
    },
  },
};

export default privateCompany;
