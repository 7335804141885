import US from '../us';
import { BusinessTypesIds, I18n } from 'src/types';
import soleTrader from './soleTrader';
import privateCompany from './privateCompany';
import trust from './trust';
import nonprofit from './nonprofit';
import { thankYouPageMessages } from 'src/i18n/thankyouMessages';
import partnership from 'src/i18n/locales/au/partnership';

const thankYouPage = {
  personalDocuments: [
    {
      text: 'Name, contact details and DOB for directors and shareholders who own 25% or more of the business',
    },
    {
      text: 'Photo ID for yourself and listed directors/shareholders',
      highlight: 'if we fail to electronically verify the identity',
    },
  ],
  personalDocumentsForPassfortOnboarding: [
    {
      text: 'Be prepared to provide details related to all persons who own directly or indirectly 25% or more of the company, and all persons with the right to exercise significant influence/control/decision making authority for the company',
    },
    {
      text: 'Photo ID for yourself and listed directors/shareholders',
      highlight: 'if we fail to electronically verify the identity',
    },
  ],
  businessDocuments: [
    {
      text: 'Proof of address for trading address and a transactional bank statement for the business',
      highlight:
        'if your registered and trading address differ or your business is less than a year old',
    },
  ],
};

function getBusinessNumber(entityTypeId, country): string {
  if (country && country.toUpperCase() === 'AU') {
    return 'ABN number (or ACN number)';
  }
  return 'Business number';
}

export const auDefault = (entityTypeId: string, country: string): I18n => {
  const defaults = US(entityTypeId, country);
  return {
    ...defaults,
    registrationPage: {
      ...defaults.registrationPage,
      userAgreement: [
        {
          link: 'https://www.ofx.com/en-au/legal/privacy-policy/',
          text: 'We use your personal information in accordance with our',
          linkText: ' Privacy Policy',
        },
        {
          link: '',
          text: ', which we encourage you to read.',
          linkText: '',
        },
      ],
    },
    businessDetailsPage: {
      ...defaults.businessDetailsPage,
      businessNumber: getBusinessNumber(entityTypeId, country),
      relatedEntity: {
        ...defaults.businessDetailsPage.relatedEntity,
        relatedEntityBusinessName: 'Name of the',
        relatedEntityBusinessNumber: 'ABN',
      },
    },
    locale: 'au',
    dateFormat: 'dd/MM/yyyy',
    thankYouPage: {
      ...thankYouPageMessages,
      ...thankYouPage,
    },
  };
};

const AU = (entityType: string, country: string): I18n => {
  switch (entityType) {
    case BusinessTypesIds.SoleTrader:
      return soleTrader(country);
    case BusinessTypesIds.Trust:
      return trust(country);
    case BusinessTypesIds.NonProfit:
    case BusinessTypesIds.PublicCompany:
      // Both need to hide business panel
      return nonprofit(country);
    case BusinessTypesIds.Partnership:
      return partnership(country);
    case BusinessTypesIds.PrivateCompany:
    default:
      return privateCompany(country);
  }
};

export default AU;
