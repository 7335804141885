export const newCorpOnboardingText = {
  shareholdersPage: {
    header: 'About your business controllers & shareholders',
    subHeader:
      'We operate in a highly regulated environment and take compliance seriously. We need to verify your business and key stakeholders.',
    topReviewMessage:
      'Please review each individual to ensure all required information is provided.',
    topInfoMessage: {
      bold: 'Please ensure the above list is correct',
      normal:
        'and all information required has been provided before clicking confirm.',
    },
    errorInUBOListDialog: {
      headerText: "We couldn't verify your shareholders",
      subHeaderText:
        'Unfortunately we encountered an error while trying to verify your shareholder information. Please check all information and try again.',
      button: 'Go back',
    },
    confirmCheckUBOLaterDialog: {
      headerText: "Your changes won't be saved",
      subHeaderText:
        'This action will discard any changes you have made specifically on this page and take you to the Dashboard. Are you sure you want to do it later?',
      button: "Yes, I'll do it later",
      link: 'No, take me back',
    },
    errorInSendingUBOsDialog: {
      headerText: 'There was an error sending your shareholders.',
      subHeaderText:
        'Unfortunately we encountered an error while trying to send your shareholder information. Please try again.',
      button: 'Go back',
    },
    optionalFieldsBeforeConfirmationUBO: {
      headerText: 'Have you considered our optional fields?',
      subHeaderText:
        'Optional fields can greatly speed up the process. If you have this information with you, you can go back and enter them in. Otherwise, proceed to confirmation.',
      proceedBtn: 'Proceed',
      goBackBtn: 'Go back',
    },
    bottomReviewMessage:
      'By clicking confirm I attest that the information above is true, accurate and complete to the best of my knowledge.Furthermore, I understand that any intentional material omission or falsification may result in termination of my and the entity’s relationship with OFX.',
    buttonLabel: 'Confirm',
    newOnboardingSpinner: [
      {
        firstLineMessage: "We're gathering your company information.",
        secondLineMessage: 'This could take a minute...',
      },
      {
        firstLineMessage: "We're gathering your company information.",
        secondLineMessage: 'Almost there...',
      },
    ],
    spentaErrorDialog: {
      header: "Sorry, we're unable to proceed",
      subHeader:
        'A system issue has prevented us from processing your request. Please click <a href="https://www.ofx.com/en-us/contact-us/">here</a> and reach out to an OFXpert in your region.',
      button: 'GO TO DASHBOARD',
    },
  },
  addShareholdersBox: {
    topMessage:
      'Add all shareholders who own 25% or more, plus the directors of your business.',
    bottomMessage:
      'Ensure ALL individual shareholders that directly or indirectly own 25% or more of the shares or voting rights in the business PLUS all the significant controllers with decision making authority for your business are added below.',
    bottomMessageSecondary: 'You can add up to 5 entries.',
    buttonLabel: 'Add a controller or shareholder',
  },
};
