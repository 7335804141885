import { I18n, BusinessTypesIds } from '../../../types';
import { sgDefault } from './index';

const thankyouPage = {
  personalDocuments: [
    {
      text: 'NRIC (front & back)',
    },
    {
      text: 'A different form of a photo ID and proof of residential address',
      highlight:
        'if unable to provide NRIC or if the residential address doesn’t match the NRIC',
    },
  ],
  businessDocuments: [],
};

const uploadPage = {
  header: 'We require the following documents to verify your business',
  subHeader:
    'We are a fully regulated business and take security seriously, so we need some documentation to verify your business.',
  businessDocuments: {
    heading: 'Business documents',
    displayAsList: false,
    items: [],
  },
  personalDocuments: {
    heading: 'Personal documents',
    displayAsList: true,
    items: [
      {
        description: '<strong>NRIC (front & back)</strong>',
      },
      {
        description:
          'If unable to provide NRIC or if the residential address doesn’t match the NRIC, a photo ID in the form of either a <strong>Passport or FIN card (front & back) and proof of residential address</strong> dated in the last three months. This can be:',
        listItems: ['Utility bill', 'Bank statement', 'Government letter'],
      },
    ],
    noPersonalDocumentText: 'No Personal Documents Required at this time.',
  },
};

const i18n = (country: string): I18n => {
  return {
    ...sgDefault(BusinessTypesIds.SoleTrader, country),
    thankYouPage: {
      ...sgDefault(BusinessTypesIds.SoleTrader, country).thankYouPage,
      personalDocuments: thankyouPage.personalDocuments,
      businessDocuments: thankyouPage.businessDocuments,
    },
    uploadPage,
  };
};

export default i18n;
