import { I18n, BusinessTypesIds } from '../../../types';
import { hkDefault } from './index';
import soleTraderDefaults from '../../soleTraderDefaults';

const thankyouPage = {
  personalDocuments: [
    {
      text: 'Photo ID in the form of either a Hong Kong Permanent ID card or Passport',
    },
  ],
  businessDocuments: [],
};

const i18n = (country: string): I18n => {
  return {
    ...hkDefault(BusinessTypesIds.SoleTrader, country),
    thankYouPage: {
      ...hkDefault(BusinessTypesIds.SoleTrader, country).thankYouPage,
      personalDocuments: thankyouPage.personalDocuments,
      businessDocuments: thankyouPage.businessDocuments,
    },
    uploadPage: {
      ...soleTraderDefaults.uploadPage,
      personalDocuments: {
        heading: 'Personal documents',
        displayAsList: false,
        items: [
          {
            description:
              'Photo ID in the form of either a <strong>Hong Kong Permanent ID card or Passport</strong>',
          },
          {
            description: 'Digital photos or scans are acceptable.',
          },
        ],
        noPersonalDocumentText: 'No Personal Documents Required at this time.',
      },
      businessDocuments: {
        ...soleTraderDefaults.uploadPage.businessDocuments,
        items: [],
      },
    },
  };
};

export default i18n;
